<template>
  <div class="live">
    <div class="tilte">
          <div></div>
          <span>{{$t('mine.menuList[2]')}}</span>
      </div>
      <div class="live-List">
        <div class="list" v-for="(item,index) of shoppingCartList" :key="index">
          <img class="pic" :src="item.cover" alt="">
          <div class="productDatil">
            <div class="name dis">
              <span>{{isChinese() ? item.name : item.nameEng}}</span>
              <span>¥{{item.price}}</span>
            </div>
            <div class="sku">
              {{isChinese() ? item.keyName : item.keyNameEng}}
            </div>
            <div class="del">
              <span @click="deleteCar(item,index)">{{isChinese() ? '删除':'Delete'}}</span>
              <div class="input-number">
                  <i class="el-icon-minus font" @click="subtract(item, index)"></i>
                  <div>{{ item.number }}</div>
                  <i class="el-icon-plus font" @click="add(item, index)"></i>
              </div>
            </div>
          </div>
        </div>
       
        <div class="empty" v-if="shoppingCartList.length==0">
          <empty :title="isChinese() ? '购物车为空':'Shopping cart is empty'"></empty>
        </div>
      </div>
      <div class="after">
        <div class="right">
          <div class="totle">{{$t('mine.elses.heji')}}： ¥{{totalPic}}</div>
          <div class="button" @click="submit">
            {{$t('button.lijitijao')}}
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { listUserShoppingCart, updateShoppingCart, deleteShoppingCart} from '@/api/main.js'
import empty from '@/components/empty.vue'
export default{
  components:{empty},
  data(){
      return {
         shoppingCartList:[],
        isLoding: true,
        totalPic: 0,//商品总价
        
      }
  },
  
  mounted(){
    this.getlistUserShoppingCart()
  },
  methods:{
    del(index,id){
      deleteShoppingCart({
        shoppingCartId:id
      }).then(res=>{
        if(res.code==0){
          if (this.isChinese()) {
            this.$message({
                message: '删除成功',
                type: 'success'
            });
          }else{
            this.$message({
                message: 'successfully delete',
                type: 'success'
            });
          }
          this.list.splice(index,1)
        }else{
          this.showMessage(res)
        }
      })
    },
    deleteCar(item, index) {
      this.showChat = false
      var obj = {}
      if (this.isChinese()) {
        obj = {
          title: '您确定要删除？',
          del: '删除',
          confirm: '确定',
          cancel: '取消'
        }
      } else {
        obj = {
          title: 'You are sure you want to delete？',
          del: 'delete',
          confirm: 'confirm',
          cancel: 'cancel'
        }
      }
      this.$confirm(obj.title, obj.del, {
        confirmButtonText: obj.confirm,
        cancelButtonText: obj.cancel,
        type: 'warning'
      }).then(() => {
        this.showChat = true
        this.deleteShoppingCart(item,index)
      }).catch(() => {
        this.showChat = true
      });

    },
     //删除购物车商品
     deleteShoppingCart(item,index){
      deleteShoppingCart({
          shoppingCartId: item.id
        }).then(res => {
          if (res.code == 0) {
            this.$message({
              type: 'success',
              message: this.isChinese() ? '删除成功':'Successfully delete'
            });
            this.shoppingCartList.splice(index, 1)
            this.updateTotalPic();//更新总价
          } else {
            this.showMessage();
          }
        })
    },
    subtract(item, index) {//数量减
      if (!this.isLoding) return;
     
      if(item.number==1){//如果数量为1在删除那就清除这个商品
        this.deleteShoppingCart(item,index);
        return
      }
      this.isLoding = false
      var nber = item.number;
      updateShoppingCart({
        id: item.id,
        number: nber - 1
      }).then(res => {
        this.isLoding = true;
        if (res.code == 0) {
          this.shoppingCartList[index].number = nber - 1;
          this.updateTotalPic();//更新总价
        } else {
          this.showMessage();
        }
      })
    },
    add(item, index) {//数量加
      if (!this.isLoding) return;
      this.isLoding = false
      var nber = item.number;
      updateShoppingCart({
        id: item.id,
        number: nber + 1
      }).then(res => {
        this.isLoding = true;
        if (res.code == 0) {
          this.shoppingCartList[index].number = nber + 1;
          this.updateTotalPic();//更新总价
        } else {
          this.showMessage();
        }
      })
    },
    updateTotalPic() {//计算价格
      let bigPrice = 0
      this.shoppingCartList.find((item) => {
        bigPrice += (item.price * item.number) + item.freight //freight 运费不知道加不加
      })
      this.totalPic = bigPrice
    },
    submit(){
      if(this.shoppingCartList.length==0){
          this.$message({
              type: 'warning',
              message: this.isChinese() ? '购物车为空':'Shopping cart is empty'
            });
        return
      }
      this.$router.push({name:'submit',params:{cartList:JSON.stringify(this.shoppingCartList)}})
    },
    getlistUserShoppingCart(){
      listUserShoppingCart({
      }).then(res=>{
        if(res.code==0){
          this.shoppingCartList = res.data
          this.updateTotalPic()
        }else{
          this.showMessage(res)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.live{
  width: 880px;
  .tilte{
      display: flex;
      align-items: center;
      padding: 20px 0px;
      div{
          width: 2px;
          height: 24px;
          background: #333333;
          margin-right: 10px;
      }
      span{
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
      }
  }
  .live-List{
    .list{
      padding: 20px 0px;
      border-bottom: 1px solid #ECECEC;
      display: flex;
        justify-content: space-between;
      .pic{
        width: 120px;
        height: 120px;
        background: #ECE8F1;
      }
      .productDatil{
        width: 740px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .dis{
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .name{
          span{
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
          i{
            font-size: 25px;
          }
        }
        .suk{
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .del,.sku{
          span{
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #AAAAAA;
          }
        }
        .del{
          display:flex;
          justify-content: space-between;
          align-items: center;
        }
        .input-number{
          display:flex;
          justify-content: space-between;
          align-items: center;
          width: 110px;
          height: 30px;
          background: #F2F2F2;
          padding: 5px;
          div{
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 22px;
            
          }
          .font{
            font-size: 19px;
            color: #000000;
          }
        }
      }
    }
  }
  .after{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .right{
      margin-top: 20px;
      .totle{
        font-size: 22px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        text-align: right;
        padding: 20px 0px;
      }
      .button{
        background: #000000;
        padding: 16px 80px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        border:1px solid #000000;
        cursor: pointer;
      }
      .button:hover{
        color: #000000;
        background: #FFFFFF;
        transition: all .2s ease .2s;
      }
    }
  }
}
</style>